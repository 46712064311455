body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#header{
  background-color:darkslategray;
  position: sticky;
  top: 0;
  height: 75px;
  color: white
}


#footer {
  position: fixed;
  bottom: 1.5%;
  height: 3.5rem; 
  overflow: hidden;
  background-color: lightgray;

}

.footerLink:hover{
  color: green;
  text-decoration: none;
}

.footerLink{
  /* margin-left: 1%; */
  color: white;
}
.headerTitle{
  text-align: center;
  font-size: x-large;
}
.positionRight{
  float: right;
}
.positionLeft{
  float: left;
}

.positionCenter{
  text-align: center;
}

.flashStyling{
  color: whitesmoke;
  background-color: green;
  width: 40vw;
  height: 50px;
  color:white;
  position:absolute;
  font-size: larger;
  text-align: center;
  padding: 10px;
  text-align: center;
  margin: 0 auto;
	top:1%;
	bottom: 0;
	left: 0;
  right: 0;
  border-radius: 25px;
}


@media screen and (max-width: 600px) {
  .footerLink:hover{
    color: green;
    text-decoration: none;
  }
}
